<template>
    <div class="cs-form-block cs-block" :class="`${styleClass}`">
        <div class="cs-base-block">
            <div class="container">
                <CSForm :id="data.id" ></CSForm>
            </div>
        </div>
    </div>
</template>

<script>

import {computed} from '../libs/common-fn';

export default {
    name: 'Form',
    components: { 
        CSForm: () => import("../components/CSForm.vue"),
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
    },
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    computed: {
		...computed('Form'),
		styleClass() {
			let result = ''
    
			if(this.data.style) result += `cs-style-${this.data.style}`

			if (this.data.style == 'light' || this.data.style == 'color') result += ' has-background'

			return result
		}
	},
    methods: {
        submitForm(data) {
            console.log(data)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";

.cs-form-block {
    .cs-system-form-block {
		margin-top: 30px;
	}
    &.cs-style- {
		&color {
			@include cs-block-color;
		}

		&light {
			@include cs-block-light-image;
			.cs-sub-title {
				color: $sub_title_color_light;
			}
		}

		&dark {
			@include cs-block-dark;
			.cs-sub-title {
				color: $sub_title_color_dark;
			}
		}
	}

    .cs-sub-title {
		font-weight: 400;
		padding: 10px 0;
		font-size: 20px;
	}
}
</style>
